<template>
  <div v-if="formattedReplies">
    <div class="error">{{ getReplyError }}</div>
    <div class="reply-window">
      <!-- <div class="error">{{ formError }}</div> -->
      <div v-for="reply in formattedReplies" :key="reply.id">
        <div class="single-reply">
          <div class="info">
            <div class="created-at">
              {{ reply.createdAt }}
            </div>
            <div class="d-flex justify-content-start align-items-baseline">
              <div class="name">
                {{ reply.commenterDisplayName }}
              </div>
              <div class="legal" v-if="reply.isHyping">
                *issuer founder, employee, or otherwise compensated to promote
                this offering*
              </div>
            </div>
            <div class="message">
              {{ reply.comment }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <!-- <form v-if="!isLoading" @submit.prevent="handleReply" class="edit-form">
    <div class="form-outline mb-4">
      <label>Reply</label>
      <textarea v-model="comment" required class="form-control"> </textarea>
    </div>
    <div v-if="!isLoading" class="row text-center">
      <div class="col">
        <button class="btn btn-primary">Reply</button>
      </div>
    </div>
  </form> -->
</template>

<script>
import { computed } from "@vue/runtime-core";
import getCollection from "@/composables/getCollection";
import { formatDistanceToNow } from "date-fns";

export default {
  props: ["commentId", "replyCount"],
  setup(props) {
    const {
      isLoading,
      error: getReplyError,
      documents: replies
    } = getCollection(`offeringComments/${props.commentId}/replies`, "", [
      "createdAt",
      "asc"
    ]);

    // const { error: createReplyError, addDoc: addReply } = useCollection(
    //   `offeringComments/${props.commentId}/replies`
    // );

    const formattedReplies = computed(() => {
      if (replies.value) {
        if (replies.value.length > 0) {
          return replies.value.map(reply => {
            let time = formatDistanceToNow(reply.createdAt.toDate());
            return { ...reply, createdAt: time };
          });
        } else {
          return null;
        }
      }
    });

    // const handleReply = async () => {
    //   try {
    //     isPending.value = true;
    //     formError.value = null;
    //     await addReply({
    //       comment: comment.value,
    //       commenterId: user.value.uid,
    //       commenterDisplayName: user.value.displayName,
    //       createdAt: timestamp()
    //     });
    //     isPending.value = false;
    //     if (createReplyError.value) {
    //       formError.value = createReplyError.value;
    //     } else {
    //       comment.value = "";
    //       console.log("ReplyCount: ", props.replyCount);
    //       await updateComment({
    //         updatedAt: timestamp(),
    //         updatedBy: user.value.uid,
    //         replyCount: props.replyCount + 1
    //       });
    //       if (useDocError.value) {
    //         formError.value = useDocError.value;
    //       }
    //     }
    //   } catch (err) {
    //     formError.value = "System Error: " + err.message;
    //   }
    // };

    return {
      // comment,
      // formError,
      isLoading,
      getReplyError,
      formattedReplies
      // handleReply
    };
  }
};
</script>

<style>
</style>