<template>
  <div>
    <div v-if="commentError">{{ commentError }}</div>
    <div v-for="offeringComment in offeringComments" :key="offeringComment.id">
      <div class="head">
        {{ offeringComment.subject }}
      </div>
      <div class="d-flex justify-content-start align-items-baseline">
        <div class="name">
          {{ offeringComment.commenterDisplayName }}
        </div>
        <div class="legal" v-if="offeringComment.isHyping">
          *issuer founder, employee, or otherwise compensated to promote this
          offering*
        </div>
      </div>
      <div class="created-at">
        {{ offeringComment.date }}
      </div>
      <p>{{ offeringComment.comment }}</p>
      <div>
        <Replies
          :commentId="offeringComment.id"
          :replyCount="offeringComment.replyCount"
        />
      </div>
    </div>
  </div>
</template>

<script>
import getCollection from "@/composables/getCollection";
import Replies from "@/components/comments/Replies.vue";

export default {
  props: ["offeringId"],
  components: { Replies },
  setup(props) {
    const { error: commentError, documents: offeringComments } = getCollection(
      "offeringComments",
      [["offeringId", "==", props.offeringId]],
      ["createdAt", "asc"]
    );

    return {
      commentError,
      offeringComments
    };
  }
};
</script>

<style>
</style>